import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {combineLatest, from, iif, Observable, of, Subject} from "rxjs";
import {delay, distinctUntilChanged, filter, finalize, map, switchMap, take, takeUntil} from "rxjs/operators";
import { AppService } from "./state/app/app.service";
import {SidenavComponent} from "@zonar-ui/sidenav";
import {
    sidenavFooterConfig,
    sidenavGlobalConfig,
    sidenavHeaderConfig
} from "./reports/shared-report-components/sidenav.config";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ACTIVE_ACCOUNT_PARAM, LocalStorageService, PREV_COMPANY_ID} from "./local-storage.service";
import {PermissionsService} from "@zonar-ui/auth";
import {DataDogService} from "./datadog.service";

@Component({
	selector: 'app-root',
    templateUrl: 'app-root.component.html'
})
export class AppRootComponent implements OnInit, OnDestroy  {
    @ViewChild('sidenav') zonarSideNav: SidenavComponent;
    sidenavConfig = sidenavGlobalConfig;
    sidenavHeader = sidenavHeaderConfig;
    sidenavFooter= sidenavFooterConfig;
    public isLoading$!: Observable<boolean>;
    public account = undefined;
    public companyId = undefined;
    public destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private appService: AppService,
        private localStorageService: LocalStorageService,
        private permissionsService: PermissionsService,
        private router: Router,
        private route: ActivatedRoute,
        private datadogService: DataDogService,
    ) {
        // Adding 100 ms delay to avoid flickering effect 
        this.isLoading$= this.appService.isAppDataLoading$.pipe(delay(100));
    }

    ngOnDestroy() {
        this.datadogService.stopSessionReplayRecording();
        this.destroy$.next(true);
    }

    ngOnInit() {
        //Monitoring user in RUM here right after authentication
        this.datadogService.startSessionReplayRecording();
        this.account = this.localStorageService.get('activeAccount');
    }

    selectCompany(selection: { value: string; title: string }): void {
        this.destroy$.next(true);
        this.account = this.localStorageService.get('activeAccount');
        this.companyId = this.localStorageService.get('prevCompanyId');
        // new company selection and after being routed to no-permission-route
        if(this.companyId !== selection.value) {
            if (this.router.url.includes('no-permission-route')) {
                this.appService.setCompanyInSidenav(selection.value).subscribe(
                    ([acc, list]) => {
                        this.permissionsService.setCurrentCompanyContextById(selection.value);
                        // remove the selectedEntity
                        this.localStorageService.remove('selectedEntity');
                        this.router.navigate(['/']).then(() => {
                        }, err => console.log(err));
                    },
                    error => {
                        console.error(error);
                    }
                );
            } else {
                if (this.account && (this.companyId !== selection.value)) {
                    from([1]).pipe(
                        take(1),
                        map(num => num),
                        switchMap(num =>
                        combineLatest([
                            this.isLoading$.pipe(map(isLoading => isLoading), takeUntil(this.destroy$)),
                            this.appService.setCompanyInSidenav(selection.value).pipe(
                                take(1),
                                map(([acc, list]) => acc))
                        ])),
                        switchMap(([isLoading, acc]) => {
                            //once the loading is ever true on page load, then the observable isLoading should stop listening.
                            if(isLoading === true) {
                                this.destroy$.next(true);
                            }
                            return of([isLoading, acc]);
                        }),
                        finalize(() => {
                            this.destroy$.next(true);
                        })
                    ).subscribe(
                        ([isLoading, acc]) => {
                            //update the account and the companyId from localstorage
                            this.account = this.localStorageService.get('activeAccount');
                            this.companyId = this.localStorageService.get('prevCompanyId');
                            if(acc !== this.account && !isLoading) {
                                this.permissionsService.setCurrentCompanyContextById(selection.value);
                                this.localStorageService.set(ACTIVE_ACCOUNT_PARAM, acc);
                                this.localStorageService.set(PREV_COMPANY_ID, selection.value);
                                // remove the selectedEntity
                                this.localStorageService.remove('selectedEntity');
                                const queryParams: Params = { active: acc };
                                this.router.navigate(
                                    [],
                                    {
                                        relativeTo: this.route,
                                        queryParams,
                                    }
                                ).then(() => {
                                        window.location.reload();
                                }).catch((err) => console.log(err));
                            }
                        },
                        error => {
                            console.error(error);
                            this.destroy$.next(true);
                            this.router.navigate(
                                ['no-permission-route'],
                                {
                                    replaceUrl: true,
                                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                                }
                            );
                        }
                    )
                }
            }
        }
    }
}
